@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'agile-sans-regular';
  src: url('/public/assets/fonts/AgileSans-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'agile-sans-bold';
  src: url('/public/assets/fonts/AgileSans-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'agile-sans-extralight';
  src: url('/public/assets/fonts/AgileSans-Extralight.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'indarung-personal';
  src: url('/public/assets/fonts/IndarungPersonal.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}


html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  
  background-color: #333333;
  flex: 1;
  display: flex;
  justify-content: center;
  @apply text-base font-agile-sans-regular;
}

.formInput {
  @apply border border-inherit rounded-md p-2 mr-0.5;
}

html {
  scroll-behavior: smooth;
}



.bodyContainer {
  width: 100%;
  flex:1;
  justify-content: center;


}

/* Set max-width based on screen size */
@media (min-width: 640px) {
  .bodyContainer {

  }
}

@media (min-width: 768px) {
  .bodyContainer {

  }
}

@media (min-width: 1060px) {
  .bodyContainer {

  }
}

@media (min-width: 1280px) {
  body {
    
    max-width: 1380px;
    margin: auto;
  }
}